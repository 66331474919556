import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

function PageTargeting({ featureId = 'false', pageType = '' }) {
  // Page targeting values for Google Ad Manager
  return (
    <Head>
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            var tude = window.tude || {cmd:[]};
            tude.cmd.push(function() {
              tude.setPageTargeting({
                featureId: '${featureId}',
                pageType: '${pageType}',
              });
            });

            window.Raven = window.Raven || { cmd: [] };
            window.Raven.cmd.push(({ config }) => {
            config.setCustom({
              param1: 'pageType-${pageType}',
              param2: 'featureId-${featureId}',
            });
          });
          `,
        }}
        type="text/javascript"
      />
    </Head>
  );
}

PageTargeting.propTypes = {
  featureId: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
};

export default PageTargeting;
